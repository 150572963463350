import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import usePageLoading from 'components/utils/usePageLoading'

import { TemplateContextProvider } from 'components/post/TemplateProvider'
import { PlacesContextProvider } from 'components/post/PlacesProvider'

import SEO from "../components/seo"
import Header from "components/header"
import Sections from "components/sections"
import Footer from "components/footer"

import "styles/pages.scss"

const Home = ({ data }) => {
  const pageData = data.allWpPage.edges[0].node
  const pageLoader = usePageLoading()

  //ReactDOM.render(<SmartBanner title = { 'Facebook'} />, document.getElementById('content'));

  return (
    <div className={`page ${pageLoader}`}>
      <TemplateContextProvider>
        <PlacesContextProvider>
          <SEO lang="en-US" data={pageData.seo} />
          <main>
            <Header />
            {pageData.sections.sections &&
              <Sections data={pageData.sections.sections} />
            }
          </main>
          <Footer hasTopRule={false} />
          </PlacesContextProvider>
      </TemplateContextProvider>
    </div>
  )
}
export default Home

export const postQuery = graphql`
  query IndexQuery {
    allWpPage(filter: { isFrontPage: { eq: true } }) {
      edges {
        node {
          id
          databaseId
          title
          date
          seo {
            title
            opengraphAuthor
            focuskw
            canonical
            opengraphDescription
            metaRobotsNoindex
            metaRobotsNofollow
            metaDesc
            metaKeywords
            opengraphImage {
              mediaItemUrl
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
          }
          sections {
            fieldGroupName
            sections {
              ... on WpPage_Sections_Sections_HighlightBlock {
                bodyText
                highlights {
                  header
                  subheader
                  link
                  image {
                    localFile {
                      publicURL
                    }
                    mediaItemUrl
                  }
                }
                heading
                cardPerRow
                fieldGroupName
              }
              ... on WpPage_Sections_Sections_HomeImpactArea {
                bodyText
                fieldGroupName
                heading
                googlePlayUrl
                vibesets {
                  ... on WpVibeset {
                    title
                    id
                    databaseId
                    slug
                    vibesetDetails {
                      exploreLink
                    }
                  }
                }
                links {
                  email
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_AppStoreCallout {
                text
                appStoreUrl
                fieldGroupName
                googlePlayUrl
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED,
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_CalloutBlock {
                fieldGroupName
                heading
                bodyText
                style
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_CitiesBlock {
                heading
                bodyText
                cardPerRow
                imageType
                fieldGroupName
                cities {
                  ... on WpCity {
                    id
                    cityDetails {
                      icon {
                        databaseId
                        localFile {
                          publicURL
                        }
                      }
                      placemarker {
                        latitude
                        longitude
                        zoom
                      }
                    }
                    name: title
                    title
                    uri
                  }
                }
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_FeaturedPost {
                fieldGroupName
                automatic
                heading
                featuredPost {
                  ... on WpPost {
                    id
                    databaseId
                    title
                    slug
                    uri
                    date
                    excerpt
                    featuredImage {
                      node {
                        caption
                        mediaItemUrl
                      }
                    }
                    author {
                      node {
                        name
                      }
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                    postDetails {
                      authorName
                      authorBio
                      customAuthor
                      vibeset {
                        ... on WpVibeset {
                          slug
                          title
                        }
                      }
                      city {
                        ... on WpCity {
                          id
                          title
                          uri
                          slug
                          databaseId
                        }
                      }
                    }
                  }
                }
                posts {
                  ... on WpPost {
                    id
                    databaseId
                    title
                    slug
                    uri
                    date
                    excerpt
                    featuredImage {
                      node {
                        caption
                        mediaItemUrl
                      }
                    }
                    author {
                      node {
                        name
                      }
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                    postDetails {
                      vibeset {
                        ... on WpVibeset {
                          id
                          title
                          databaseId
                          slug
                          vibesetDetails {
                            gradientImage {
                              mediaItemUrl
                            }
                            gradientVideo {
                              mediaItemUrl
                            }
                          }
                        }
                      }
                      city {
                        ... on WpCity {
                          id
                          title
                          uri
                          slug
                          databaseId
                        }
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_ImageBlock {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED,
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_StoryBlock {
                fieldGroupName
                blocks {
                  bodyText
                  style
                  caption
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED,
                          placeholder: BLURRED
                        )
                      }
                    }
                  }
                  vibeset {
                    ... on WpVibeset {
                      id
                      slug
                      vibesetDetails {
                        gradientImage {
                          mediaItemUrl
                        }
                        gradientVideo {
                          mediaItemUrl
                        }
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_QuotesBlock {
                quotes {
                  attribution
                  fieldGroupName
                  quote
                }
                fieldGroupName
                heading
              }
              ... on WpPage_Sections_Sections_FormBlock {
                fieldGroupName
                form
                heading
                text
              }
              ... on WpPage_Sections_Sections_TextWithForm {
                fieldGroupName
                heading
                bodyText
                layout
                form
              }
              ... on WpPage_Sections_Sections_TextColumnsIcon {
                fieldGroupName
                columns {
                  bodyText
                  fieldGroupName
                  heading
                  iconSize
                  icon {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED,
                          placeholder: BLURRED
                        )
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_TextWithImage {
                bodyText
                fieldGroupName
                heading
                layout
                textStyles
                style
                image {
                  altText
                  caption
                  localFile {
                    publicURL
                  }
                }
                vibeset {
                  ... on WpVibeset {
                    id
                    slug
                    vibesetDetails {
                      gradientImage {
                       mediaItemUrl
                      }
                      gradientVideo {
                        mediaItemUrl
                      }
                    }
                  }
                }
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  email
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_TextWithBlock {
                bodyText
                fieldGroupName
                heading
                layout
                textStyles
                style
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
                embedBlock {
                  embed
                  embedCode
                  heading
                }
                blockType
                mapBlock: mapContainer {
                  directions
                  heatmap
                  height
                  location {
                    ... on WpCity {
                      id
                      cityDetails {
                        placemarker {
                          latitude
                          longitude
                        }
                      }
                    }
                    ... on WpNeighborhood {
                      id
                      neighborhood {
                        map {
                          latitude
                          longitude
                        }
                      }
                    }
                  }
                  map {
                    latitude
                    longitude
                  }
                  map3d
                  mapTheme
                  markerStyle
                  openInApp
                  showMarkers
                  width
                  zoom
                }
              }
              ... on WpPage_Sections_Sections_TextWithImageForm {
                fieldGroupName
                heading
                bodyText
                form
                image {
                  altText
                  caption
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED,
                        placeholder: BLURRED
                      )
                    }
                  }
                }
                layout
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_TextWithImageCallout {
                fieldGroupName
                heading
                bodyText
                layout
                image {
                  altText
                  caption
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED,
                        placeholder: BLURRED
                      )
                    }
                  }
                }
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
                callout {
                  heading
                  bodyText
                  calloutLinks {
                    externalUrl
                    fieldGroupName
                    forceDownload
                    linkText
                    linkType
                    formId
                    pageLink {
                      ... on WpPage {
                        id
                        uri
                      }
                      ... on WpPost {
                        id
                        uri
                      }
                    }
                    file {
                      id
                      localFile {
                        name
                        ext
                        publicURL
                      }
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_VibesetsBlock {
                bodyText
                fieldGroupName
                heading
                vibesets {
                  vibeset {
                    ... on WpVibeset {
                      id
                      title
                      databaseId
                      slug
                      vibesetDetails {
                        exploreLink
                        tagline
                        description
                        mainVibes
                        gradientImage {
                          mediaItemUrl
                        }
                        gradientVideo {
                          mediaItemUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
